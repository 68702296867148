import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fontsource/roboto/index.css";
import "@fontsource/roboto/latin.css";

import services from "./services/services";
import ProjectService from "./services/ProjectService";
import projects from "./data/projects.json";
import articles from "./data/articles.json";
import { Project } from "./types/project";
import ArticleService from "./services/ArticleService";
import { Article } from "./types/article";

services.project = new ProjectService(projects as Project[]);
services.article = new ArticleService(articles as Article[]);
Object.freeze(services);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
