export const Contact = () => {
  return <div className="w-full h-full flex flex-col items-center text-slate-600 mt-28 font-light">
    <div className="mb-3">
      France / United Kingdom
    </div>
    <div className="mb-3">
      +33652817026
    </div>
    <div>
      williamprofit@hotmail.fr
    </div>
  </div>;
};
