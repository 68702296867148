import { useNavigate } from "react-router-dom";

type ArticleCellProps = {
  title: string;
  slug: string;
  desc: string;
  date: string;
  category: string[];
  link?: string;
};

export const ArticleCell = (props: ArticleCellProps) => {
  const nav = useNavigate();

  return (
    <div
      className="article-cell border-b border-b-slate-300 border-spacing-10 mb-10 cursor-pointer"
      onClick={() => nav(`/article/${props.slug}`)}
    >
      <div className="text-2xl text-slate-800 font-medium mb-1">
        {props.title}
      </div>
      <div className="font-light line-clamp-3 mb-2">{props.desc}</div>
      <div className="flex flex-row items-center mb-5">
        <div className="italic text-slate-500 font-light mr-5">
          {props.date}
        </div>
        {props.category.map((c) => {
          return (
            <div className="bg-slate-200 text-slate-800 text-xs font-light p-1 rounded-full mr-2" key={c}>
              {c}
            </div>
          );
        })}
      </div>
    </div>
  );
};
