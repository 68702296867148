import React from "react";
import { useNavigate } from "react-router-dom";
import pdp from "../images/pdp.jpeg";

export const Header = () => {
  const nav = useNavigate();

  return (
    <div className="flex flex-col md:flex-row md:justify-between">
      <div
        className="flex justify-center md:justify-start cursor-pointer"
        onClick={() => nav("/")}
      >
        <img
          src={pdp}
          className="rounded-full w-20 h-20 md:w-24 md:h-24"
          alt="William Profit"
        />
        <div className="my-auto ml-4">
          <div className="font-light text-3xl md:text-4xl text-slate-800 tracking-wide">
            William Profit
          </div>
          <div className="font-light italic text-sm md:text-lg text-slate-600">
            MEng Computing Graduate
          </div>
          <div className="font-light italic text-sm md:text-lg text-slate-600">
            Imperial College London
          </div>
        </div>
      </div>

      <div className="flex flex-end mt-4 md:mt-0 justify-center md:justify-start">
        <div className="my-auto mr-3 pt-2">
          <a
            href="https://www.linkedin.com/in/williamprofit/"
            target={"_blank"}
            rel="noreferrer"
          >
            <i className="fa-brands fa-linkedin-in text-md md:text-xl text-slate-600 hover:text-slate-900 cursor-pointer"></i>
          </a>
        </div>
        <div className="my-auto mr-5 pt-2">
          <a
            href="https://github.com/williamprofit/"
            target={"_blank"}
            rel="noreferrer"
          >
            <i className="fa-brands fa-github text-md md:text-xl text-slate-600 hover:text-slate-900 cursor-pointer"></i>
          </a>
        </div>
        <button
          className="btn-slate uppercase"
          onClick={(e) => {
            e.preventDefault();
            window.open("/CV.pdf", "_blank");
          }}
        >
          Download CV
        </button>
      </div>
    </div>
  );
};
