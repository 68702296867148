import { Article } from "../types/article";

class ArticleService {
  private localDb: Article[];

  constructor(localDb: Article[]) {
    this.localDb = localDb;
  }

  public getArticle(slug: string): Promise<Article> {
    return new Promise((resolve, reject) => {
      const p = this.localDb.find((p) => p.slug === slug);
      p ? resolve(p) : reject();
    });
  }

  public getAllArticles(): Promise<Article[]> {
    return new Promise((resolve, reject) => {
      resolve(this.localDb);
    });
  }
}

export default ArticleService;
