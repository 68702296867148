import React from "react";
import { useNavigate } from "react-router-dom";

type ProjectCellProps = {
  slug: string;
  image: string;
  displayTitle: string;
};

export const ProjectCell = (props: ProjectCellProps) => {
  const nav = useNavigate();

  return (
    <div
      className="group flex flex-col relative duration-300 overflow-hidden cursor-pointer"
      onClick={() => nav(`/project/${props.slug}`)}
    >
      <img
        className="object-cover w-full aspect-square md:group-hover:scale-105 md:transition-scale md:duration-300"
        src={`images/${props.image}`}
        alt={props.displayTitle}
      />
      <div className="bg-slate-50 absolute bottom-0 w-full text-center p-1 border-b border-slate-300">
        <span className="text-slate-800 font-medium tracking-wider text-xl">
          {props.displayTitle}
        </span>
      </div>
    </div>
  );
};
