import { useEffect, useState } from "react";
import services from "../services/services";
import { Article } from "../types/article";
import { ArticleCell } from "./ArticleCell";

export const Articles = () => {
  let [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    services.article.getAllArticles().then((articles) => {
      setArticles(articles);
    });
  }, []);

  return (
    <div className="articles flex flex-col pt-5">
      {articles.map((a) => {
        return (
          <ArticleCell
            title={a.title}
            slug={a.slug}
            desc={a.desc}
            category={a.category}
            date={a.date}
            key={a.slug}
          />
        );
      })}
    </div>
  );
};
