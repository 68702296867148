import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import services from "../services/services";
import { Article } from "../types/article";

export const ArticlePage = () => {
  const { slug } = useParams();
  const nav = useNavigate();
  let [article, setArticle] = useState<Article | null>(null);

  useEffect(() => {
    if (slug) {
      services.article
        .getArticle(slug)
        .then((a) => {
          if ("link" in a.content) {
            window.open(a.content.link, "_newtab")
            nav("/articles");
          } else {
            setArticle(a);
          }
        })
        .catch(() => {
          nav("/");
        });
    } else {
      nav("/");
    }

    return undefined;
  }, [slug, nav]);

  if (article === null) {
    return <></>;
  } else {
    return <div>TODO: Not implemented</div>;
  }
};
