import React, { useEffect, useState } from "react";
import services from "../services/services";
import { Project } from "../types/project";
import { ProjectCell } from "./ProjectCell";

export const Projects = () => {
  let [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    services.project.getAllProjects().then((projects) => {
      setProjects(projects);
    });

    return undefined;
  }, []);

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-center p-2">
        {projects.map((p) => {
          return (
            <ProjectCell
              displayTitle={p.displayTitle}
              image={p.image}
              slug={p.slug}
              key={p.slug}
            />
          );
        })}
      </div>
    </div>
  );
};
