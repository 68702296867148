import { Project } from "../types/project";

class ProjectService {
  private localDb: Project[];

  constructor(localDb: Project[]) {
    this.localDb = localDb;
  }

  public getProject(slug: string): Promise<Project> {
    return new Promise((resolve, reject) => {
      const p = this.localDb.find((p) => p.slug === slug);
      p ? resolve(p) : reject();
    });
  }

  public getAllProjects(): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      resolve(this.localDb);
    });
  }
}

export default ProjectService;
