export const About = () => {
  return (
    <div className="">
      <img
        src={`/images/toulouse.jpeg`}
        alt="Toulouse"
        className="aspect-square float-left w-1/2 object-cover mr-8 mb-4"
      />
      <div className="text-4xl text-slate-800 font-light mb-8">About me</div>
      <div className="text-slate-600 text-lg font-light mb-8 content">
        <p>
          I am a Software Engineer and graduate student from Imperial College
          London. My background and interests are two fold: Machine Learning and
          Scalable Systems. In terms of Machine Learning, I have done several
          computer vision and NLP projects as well as concluded my degree with a
          thesis on Reinforcement Learning. Regarding scalable systems, I have
          experience designing data intensive applications, having notably
          re-architectured part of Bloomberg's Foreign Exchange (FX) trading
          platform into event-driven microservices using Kafka. I have also
          written a few articles relating to software engineering, available in
          the <a href="/articles">articles</a> section.
        </p>
        <p>
          I am also interested in business and entrepreneurship. I have taken
          multiple modules from the Imperial Business School and read many books
          on the subject. As part of this interest, I typically have a few
          on-going freelance and other projects on the side.
        </p>
      </div>
    </div>
  );
};
