import React from "react";
import { Header } from "./components/Header";
import { Projects } from "./components/Projects";

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  NavLink,
} from "react-router-dom";
import { About } from "./components/About";
import { ProjectPage } from "./components/ProjectPage";
import { Contact } from "./components/Contact";
import { Articles } from "./components/Articles";
import { ArticlePage } from "./components/ArticlePage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="container mx-auto xl:w-3/5 px-5 py-5 mt-8 rounded-lg">
          <Header />
          <div className="p-5">
            <div className="flex flex-row mt-10 ml-5 mb-5 text-md md:text-xl font-light">
              <NavLink
                className={({ isActive }) => {
                  return (
                    (isActive ? "border-b " : "") +
                    "text-slate-700 border-slate-400 hover:cursor-pointer"
                  );
                }}
                to="/projects"
              >
                Projects
              </NavLink>
              <NavLink
                className={({ isActive }) => {
                  return (
                    (isActive ? "border-b " : "") +
                    "text-slate-700 border-slate-400 hover:cursor-pointer ml-8"
                  );
                }}
                to="/about"
              >
                About
              </NavLink>
              <NavLink
                className={({ isActive }) => {
                  return (
                    (isActive ? "border-b " : "") +
                    "text-slate-700 border-slate-400 hover:cursor-pointer ml-8"
                  );
                }}
                to="/articles"
              >
                Articles
              </NavLink>
              <NavLink
                className={({ isActive }) => {
                  return (
                    (isActive ? "border-b " : "") +
                    "text-slate-700 border-slate-400 hover:cursor-pointer ml-8"
                  );
                }}
                to="/contact"
              >
                Contact
              </NavLink>
              <a
                className="text-slate-700 border-slate-400 hover:cursor-pointer ml-8"
                href="https://taranis.williamprofit.com"
                target="_blank"
                rel="noreferrer"
              >
                Services
              </a>
            </div>
            <Routes>
              <Route path="/projects" element={<Projects />} />
              <Route path="/project/:slug" element={<ProjectPage />} />
              <Route path="/about" element={<About />} />
              <Route path="/articles" element={<Articles />} />
              <Route path="/article/:slug" element={<ArticlePage />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Navigate to="/projects" replace />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
