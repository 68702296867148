import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import services from "../services/services";
import { Project } from "../types/project";

export const ProjectPage = () => {
  const { slug } = useParams();
  const nav = useNavigate();
  let [project, setProject] = useState<Project | null>(null);

  useEffect(() => {
    if (slug) {
      services.project
        .getProject(slug)
        .then((p) => {
          setProject(p);
        })
        .catch(() => {
          nav("/");
        });
    } else {
      nav("/");
    }

    return undefined;
  }, [slug, nav]);

  if (project === null) {
    return <></>;
  } else {
    return (
      <div className="project-page pb-10 content">
        <img
          src={`/images/${project.image}`}
          alt={project.title}
          className="aspect-square float-left w-1/2 object-cover mr-8 mb-4"
        />
        <div className="text-4xl text-slate-800 font-light mb-1">
          {project.title}
        </div>
        <div className="italic text-slate-500 font-light mb-5">
          {project.date}
        </div>
        <div
          className="text-slate-600 text-lg font-light mb-8"
          dangerouslySetInnerHTML={{ __html: project.content }}
        ></div>

        {project.buttons ? (
          <div>
            {project.buttons.map((b) => {
              return (
                <button
                  className="btn-slate uppercase mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(b.link, "_blank");
                  }}
                  type="button"
                >
                  {b.label}
                </button>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
};
